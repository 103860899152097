import { render, staticRenderFns } from "./newsdetail.vue?vue&type=template&id=0bc90970&scoped=true"
import script from "./newsdetail.vue?vue&type=script&lang=js"
export * from "./newsdetail.vue?vue&type=script&lang=js"
import style0 from "./newsdetail.vue?vue&type=style&index=0&id=0bc90970&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0bc90970",
  null
  
)

export default component.exports